// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cn-404-js": () => import("../src/pages/cn/404.js" /* webpackChunkName: "component---src-pages-cn-404-js" */),
  "component---src-pages-cn-index-js": () => import("../src/pages/cn/index.js" /* webpackChunkName: "component---src-pages-cn-index-js" */),
  "component---src-pages-cn-leaderboard-js": () => import("../src/pages/cn/leaderboard.js" /* webpackChunkName: "component---src-pages-cn-leaderboard-js" */),
  "component---src-pages-cn-login-js": () => import("../src/pages/cn/login.js" /* webpackChunkName: "component---src-pages-cn-login-js" */),
  "component---src-pages-cn-social-media-js": () => import("../src/pages/cn/social_media.js" /* webpackChunkName: "component---src-pages-cn-social-media-js" */),
  "component---src-pages-cn-speakers-js": () => import("../src/pages/cn/speakers.js" /* webpackChunkName: "component---src-pages-cn-speakers-js" */),
  "component---src-pages-cn-video-gallery-js": () => import("../src/pages/cn/video_gallery.js" /* webpackChunkName: "component---src-pages-cn-video-gallery-js" */),
  "component---src-pages-cn-virtualactivities-js": () => import("../src/pages/cn/virtualactivities.js" /* webpackChunkName: "component---src-pages-cn-virtualactivities-js" */),
  "component---src-pages-cn-virtualrun-js": () => import("../src/pages/cn/virtualrun.js" /* webpackChunkName: "component---src-pages-cn-virtualrun-js" */),
  "component---src-pages-id-404-js": () => import("../src/pages/id/404.js" /* webpackChunkName: "component---src-pages-id-404-js" */),
  "component---src-pages-id-index-js": () => import("../src/pages/id/index.js" /* webpackChunkName: "component---src-pages-id-index-js" */),
  "component---src-pages-id-leaderboard-js": () => import("../src/pages/id/leaderboard.js" /* webpackChunkName: "component---src-pages-id-leaderboard-js" */),
  "component---src-pages-id-login-js": () => import("../src/pages/id/login.js" /* webpackChunkName: "component---src-pages-id-login-js" */),
  "component---src-pages-id-social-media-js": () => import("../src/pages/id/social_media.js" /* webpackChunkName: "component---src-pages-id-social-media-js" */),
  "component---src-pages-id-speakers-js": () => import("../src/pages/id/speakers.js" /* webpackChunkName: "component---src-pages-id-speakers-js" */),
  "component---src-pages-id-video-gallery-js": () => import("../src/pages/id/video_gallery.js" /* webpackChunkName: "component---src-pages-id-video-gallery-js" */),
  "component---src-pages-id-virtualactivities-js": () => import("../src/pages/id/virtualactivities.js" /* webpackChunkName: "component---src-pages-id-virtualactivities-js" */),
  "component---src-pages-id-virtualrun-js": () => import("../src/pages/id/virtualrun.js" /* webpackChunkName: "component---src-pages-id-virtualrun-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaderboard-js": () => import("../src/pages/leaderboard.js" /* webpackChunkName: "component---src-pages-leaderboard-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-social-media-js": () => import("../src/pages/social_media.js" /* webpackChunkName: "component---src-pages-social-media-js" */),
  "component---src-pages-speakers-js": () => import("../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-video-gallery-js": () => import("../src/pages/video_gallery.js" /* webpackChunkName: "component---src-pages-video-gallery-js" */),
  "component---src-pages-virtualactivities-js": () => import("../src/pages/virtualactivities.js" /* webpackChunkName: "component---src-pages-virtualactivities-js" */),
  "component---src-pages-virtualrun-js": () => import("../src/pages/virtualrun.js" /* webpackChunkName: "component---src-pages-virtualrun-js" */),
  "component---src-pages-vn-404-js": () => import("../src/pages/vn/404.js" /* webpackChunkName: "component---src-pages-vn-404-js" */),
  "component---src-pages-vn-index-js": () => import("../src/pages/vn/index.js" /* webpackChunkName: "component---src-pages-vn-index-js" */),
  "component---src-pages-vn-leaderboard-js": () => import("../src/pages/vn/leaderboard.js" /* webpackChunkName: "component---src-pages-vn-leaderboard-js" */),
  "component---src-pages-vn-login-js": () => import("../src/pages/vn/login.js" /* webpackChunkName: "component---src-pages-vn-login-js" */),
  "component---src-pages-vn-social-media-js": () => import("../src/pages/vn/social_media.js" /* webpackChunkName: "component---src-pages-vn-social-media-js" */),
  "component---src-pages-vn-speakers-js": () => import("../src/pages/vn/speakers.js" /* webpackChunkName: "component---src-pages-vn-speakers-js" */),
  "component---src-pages-vn-video-gallery-js": () => import("../src/pages/vn/video_gallery.js" /* webpackChunkName: "component---src-pages-vn-video-gallery-js" */),
  "component---src-pages-vn-virtualactivities-js": () => import("../src/pages/vn/virtualactivities.js" /* webpackChunkName: "component---src-pages-vn-virtualactivities-js" */),
  "component---src-pages-vn-virtualrun-js": () => import("../src/pages/vn/virtualrun.js" /* webpackChunkName: "component---src-pages-vn-virtualrun-js" */),
  "component---src-pages-zh-404-js": () => import("../src/pages/zh/404.js" /* webpackChunkName: "component---src-pages-zh-404-js" */),
  "component---src-pages-zh-index-js": () => import("../src/pages/zh/index.js" /* webpackChunkName: "component---src-pages-zh-index-js" */),
  "component---src-pages-zh-leaderboard-js": () => import("../src/pages/zh/leaderboard.js" /* webpackChunkName: "component---src-pages-zh-leaderboard-js" */),
  "component---src-pages-zh-login-js": () => import("../src/pages/zh/login.js" /* webpackChunkName: "component---src-pages-zh-login-js" */),
  "component---src-pages-zh-social-media-js": () => import("../src/pages/zh/social_media.js" /* webpackChunkName: "component---src-pages-zh-social-media-js" */),
  "component---src-pages-zh-speakers-js": () => import("../src/pages/zh/speakers.js" /* webpackChunkName: "component---src-pages-zh-speakers-js" */),
  "component---src-pages-zh-video-gallery-js": () => import("../src/pages/zh/video_gallery.js" /* webpackChunkName: "component---src-pages-zh-video-gallery-js" */),
  "component---src-pages-zh-virtualactivities-js": () => import("../src/pages/zh/virtualactivities.js" /* webpackChunkName: "component---src-pages-zh-virtualactivities-js" */),
  "component---src-pages-zh-virtualrun-js": () => import("../src/pages/zh/virtualrun.js" /* webpackChunkName: "component---src-pages-zh-virtualrun-js" */)
}

