module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"Prudential MDRT Annual Meeting 2020","start_url":"/prumdrt2020","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/prumdrt2020/white_logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156518594-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
